@value fontLarsseitRegular from "../Typography.module.css";
@value borderRadius from "../Sizes.module.css";
@value textWhite, textGrey, textBlack, badgeOrange from "../Colors.module.css";
@value headingMargin2 from "../components/Heading.module.css";
@value mediaPanelFullMinWidth from "../components/PlanPanel.module.css";

.available {}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.advantage {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 1rem;
  min-width: 100px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

@media (min-width: 330px) {
  .advantage {
    margin: 2rem;
    height: 140px;
  }
}

.noContract {
  composes: advantage;
  background-image: url("../../assets/advantages/no-contract.png");
  background-size: 65px;
  background-position-y: 6px;
}

.noRateHikes {
  composes: advantage;
  background-image: url("../../assets/advantages/no-rate-hikes.png");
  background-size: 90px;
  background-position-y: 8px;
}

.noHiddenFees {
  composes: advantage;
  background-image: url("../../assets/advantages/no-hidden-fees.png");
  background-size: 55px;
  background-position-y: 1px;
}

.freeInstallation {
  composes: advantage;
  background-image: url("../../assets/advantages/free-installation.png");
  background-size: 100px;
  background-position-y: 20px;
}

.promoCodeNote {
  color: textGrey;
}

.outlineFont {
  color: textWhite;
  letter-spacing: 1px;
  text-shadow:
    1px 0 0 textBlack,
    -1px 0 0 textBlack,
    0 1px 0 textBlack,
    0 -1px 0 textBlack,
    1px 1px 0 textBlack,
    1px -1px 0 textBlack,
    -1px -1px 0 textBlack,
    -1px 1px 0 textBlack;
}

@supports (-webkit-text-stroke: 1px black) {
  .outlineFont {
    text-shadow: none;
    -webkit-text-stroke: 1px textBlack;
  }
}

@supports (text-stroke: 1px black) {
  .outlineFont {
    text-shadow: none;
    text-stroke: 1px textBlack;
  }
}

.headingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: headingMargin2;
  text-align: center;
}

@media mediaPanelFullMinWidth {
  .headingWrapper {
    justify-content: flex-start;
    text-align: left;
  }
}

.badge {
  display: inline-block;
  position: relative;
  top: -0.2rem;
  background: badgeOrange;
  padding: .6rem 1rem .3rem 1rem;
  margin-left: 1rem;
  border-radius: borderRadius;
}

.panels {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: .6rem 0;
}

@media mediaPanelFullMinWidth {
  .panels {
    align-items: stretch;
  }
}

@media (min-width: 1020px) {
  .panels {
    width: 1000px;
  }
}
