@value textWhite, calendarBorder, calendarTopBg, calendarChosen, calendarAvailable, calendarUnavailable from "../Colors.module.css";
@value borderRadius from "../Sizes.module.css";
@value fontSofiaProBold from "../Typography.module.css";

@value calendarWidth: 262px;

.calendar {
  width: calendarWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}

.legendItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 0.5rem;
}

.legendIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2rem;
}

.legendIconChosen {
  composes: legendIcon;
  background-color: calendarChosen;
}

.legendIconAvailable {
  composes: legendIcon;
  background-color: calendarAvailable;
}

.legendIconUnavailable {
  composes: legendIcon;
  background-color: calendarUnavailable;
}

/*
 * Classes below match `ClassNames` interface from react-day-picker.
 * See <https://react-day-picker.js.org/api/DayPicker#classNames>
 */

.container { /* The container element */
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  font-family: fontSofiaProBold, 'sans-serif';
  border: 2px solid calendarBorder;
  border-radius: borderRadius;
  overflow: hidden;
}

.wrapper { /* The wrapper element, used for keyboard interaction */
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.interactionDisabled { /* Added to the container when there's no interaction with the calendar */
}

.navBar { /* The navigation bar with the arrows to switch between months */
  width: 100%;
  height: 0;
  z-index: 10;
  overflow: visible;
}

.navButton {
  display: inline-block;
  font-size: 2rem;
  line-height: 2rem;
  width: 2.5rem;
  height: 2rem;
  padding-top: 0.3rem;
  text-align: center;
  color: textWhite;
  cursor: pointer;
}

.navButtonPrev { /* Button to switch to the previous month */
  composes: navButton;
}

.navButtonNext { /* Button to switch to the next month */
  composes: navButton;
  float: right;
}

.navButtonInteractionDisabled { /* Added to the navbuttons when disabled with fromMonth/toMonth props */
}

.months { /* Container of the months table */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.month { /* The month's main table */
  display: table;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.caption { /* The caption element, containing the current month's name and year */
  text-transform: uppercase;
  background-color: calendarTopBg;
  display: table-caption;
  text-align: center;
  padding: 0.5rem;
}

.weekdays { /* Table header displaying the weekdays names */
  background-color: calendarTopBg;
  display: table-header-group;
  margin-top: 1rem;
}

.weekdaysRow { /* Table row displaying the weekdays names */
  display: table-row;
}

.weekday { /* Cell displaying the weekday name */
  display: table-cell;
  padding: 0.3rem 0.5rem 0.1rem 0.5rem;
  text-align: center;
}

.weekday abbr { /* Remove dashed underline */
  border-bottom: none;
  text-decoration: none;
}

.weekNumber {}

.body { /* Table's body with the weeks */
  display: table-row-group;
}

.week { /* Table's row for each week */
  display: table-row;
}

.day { /* The single day cell */
  display: table-cell;
  padding: 0.3rem 0.5rem 0.1rem 0.5rem;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border: 2px solid calendarBorder;
}

.day:first-child {
  border-left: 0;
}

.day:last-child {
  border-right: 0;
}

.week:last-child .day {
  border-bottom: 0;
}

.footer { /* The calendar footer (only with todayButton prop)*/
}

.todayButton { /* The today button (only with todayButton prop)*/
}

/* modifiers */

.today {
  text-decoration: underline;
}

.beforeToday {}

.afterToday {}

.selected {
  background-color: calendarChosen;
}

.disabled {
  background-color: calendarUnavailable;
}

.outside {
  background-color: transparent;
}

.available {}
.available:not(.selected) {
  background-color: calendarAvailable;
}

.nonServiceable {
  background-color: calendarUnavailable;
}
