.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  font-size: 1rem;
  line-height: 2.5rem;
}
