.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 500px) {
  .flexRow > *:not(:last-child) {
    margin-right: 1rem;
  }
}

.itemsGrow {}

.itemsGrow > * {
  flex-grow: 1;
}

.rowCenter {
  composes: flexRow;
  justify-content: center;
  align-items: center;
}

.rowSpaceEvenly {
  composes: flexRow;
  justify-content: space-evenly;
  align-items: flex-start;
}

.width3to1 {}

@media (min-width: 500px) {
  .width3to1 > *:first-child {
    width: 70%;
  }

  .width3to1 > *:last-child {
    max-width: 25%;
  }
}

/* Forms */

.addressRow3to1 {
  composes: flexRow itemsGrow width3to1;
}

@media (min-width: 500px) {
  .addressRow3to1 {
    flex-wrap: nowrap;
  }
}

.buttonsCenter {
  composes: rowCenter;
  margin-top: 2rem;
}

.buttonsCenter > *:not(:last-child) {
  margin-right: 1rem;
}
