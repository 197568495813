@value fieldBgSelect, fieldBgNormal from "../../Colors.module.css";
@value borderRadius from "../../Sizes.module.css";
@value labelHeight, inputFontSize, inputVerticalPadding from "./TextField.module.css";

.suggestionsContainer {
  position: relative;
}

.suggestions {
  margin: 0;
  padding: 0;
  max-height: 200px;
  border-bottom-right-radius: borderRadius;
  border-bottom-left-radius: borderRadius;
  overflow-y: auto;
  position: absolute;
  top: calc(inputFontSize + 2*inputVerticalPadding + labelHeight);
  width: 100%;
}

.suggestion {
  background: fieldBgNormal;
  border: 1px solid fieldBgNormal;
  outline: none;
  font-size: 1rem;
  margin: 0;
  padding: 10px 30px;
  list-style: none;
}

.suggestionNormal {
  composes: suggestion;
}

.suggestionActive {
  composes: suggestion;
  background: fieldBgSelect;
  border-color: fieldBgSelect;
}

.suggestionLoading {
  composes: suggestion;
}
