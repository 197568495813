@value textBlack from "../../Colors.module.css";
@value borderRadius from "../../Sizes.module.css";
@value error as textFieldError from "./TextField.module.css";

.checkbox {
  display: none;
}

.label {
  display: block;
}

.container {
  display: flex;
  flex-direction: row;
}

.fakeCheckbox {
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  top: .2rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid textBlack;
  border-radius: borderRadius;
  background-image: url("../../..//assets/check-white.svg");
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: 2px -2px;
}

.checked {
  composes: fakeCheckbox;
  background-color: textBlack;
}

.unchecked {
  composes: fakeCheckbox;
}

.text {
  display: inline-block;
  margin-left: .5rem;
}

.error {
  composes: textFieldError;
  margin-left: calc(1.5rem + 2px);
}
