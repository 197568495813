@value fieldBgSelect, fieldBgNormal, errorBorder, errorText, placeholderColor, textLightGrey from "../../Colors.module.css";
@value borderRadius from "../../Sizes.module.css";
@value smallFontSize from "../../Typography.module.css";
@value labelHeight, inputFontSize, inputVerticalPadding from "./TextField.module.css";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}


.suggestionsList {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  position: absolute;
  z-index: 50;
  top: calc(inputFontSize + labelHeight);
  width: 100%;
  max-height: 200px;
  border-bottom-right-radius: borderRadius;
  border-bottom-left-radius: borderRadius;
}

.suggestion {
  background: fieldBgNormal;
  border: 1px solid fieldBgNormal;
  outline: none;
  font-size: 1rem;
  margin: 0;
  padding: 10px 30px;
  list-style: none;
}

.suggestionHighlighted {
  background: fieldBgSelect;
  border-color: fieldBgSelect;
}

