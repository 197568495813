@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap');

@font-face {
  font-family: 'Larsseit Regular';
  src:
    local('Larsseit'),
    local('Larsseit Regular'),
    url(../fonts/larsseit/Larsseit.woff2) format('woff2'),
    url(../fonts/larsseit/Larsseit.woff) format('woff'),
    url(../fonts/larsseit/Larsseit.ttf) format('ttf');
  font-display: auto;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro Bold';
  src:
    local('Sofia Pro'),
    local('Sofia Pro Bold'),
    url(../fonts/sofia/SofiaProBold.woff2) format('woff2'),
    url(../fonts/sofia/SofiaProBold.woff) format('woff'),
    url(../fonts/sofia/SofiaProBold.ttf) format('ttf');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

body {
  font-family: Lato, -apple-system, "Segoe UI", system-ui, Roboto, "Helvetica Neue", sans-serif;
  font-size: 18px;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
