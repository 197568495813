@value calendarBorder, calendarTopBg, calendarChosen, calendarAvailable, calendarUnavailable from "../Colors.module.css";
@value borderRadius from "../Sizes.module.css";
@value fontLarsseitRegular from "../Typography.module.css";
@value calendarWidth from "./Calendar.module.css";

.pickerWrapper {
  width: calendarWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picker {
  width: 100%;
  font-family: fontLarsseitRegular, 'sans-serif';
  border: 2px solid calendarBorder;
  border-radius: borderRadius;
  overflow: hidden;
}

.window {
  cursor: pointer;
  text-align: center;
  padding: 0.3rem 0.5rem;
}

.window:not(:last-child) {
  border-bottom: 1px solid calendarBorder;
}

.windowChosen {
  composes: window;
  background-color: calendarChosen;
}

.windowAvailable {
  composes: window;
  background-color: calendarAvailable;
}

.windowUnavailable {
  composes: window;
  background-color: calendarUnavailable;
}
