@value panelBgGrey, panelBgBlue, priceSpecial from "../Colors.module.css";
@value borderRadius from "../Sizes.module.css";

@value mediaPanelFullMinWidth: (min-width: 700px);

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: borderRadius;
  padding: 1rem;
}

.panel > *:not(:last-child) {
  margin-bottom: 1rem;
}

@media mediaPanelFullMinWidth {
  .panel {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .panel > *:not(:last-child) {
    margin-bottom: 0;
  }
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panelNormal {
  composes: panel;
  background: panelBgGrey;
}

.panelSpecial {
  composes: panel;
  background: panelBgBlue;
}

/* Speed */

.speedWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
}

.speed {
  position: relative;
  top: 0.4rem;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.speedNormal {
  composes: speed;
}

.speedSpecial {
  composes: speed;
  color: priceSpecial;
}

.speedUnit {}

/* Price */

.smallPriceText {
  position: relative;
  top: 0.2rem;
  font-size: 1.1rem;
  line-height: 1.1rem;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
}

.dollarSign {
  composes: smallPriceText;
}

.dollars {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 0 .2rem;
}

.cents {
  composes: smallPriceText;
}

/* Payment info */

.paymentInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
}

.paymentInfo {}

.paymentInfoSpecial {}
