@value fontLarsseitRegular from "../Typography.module.css";
@value borderRadius from "../Sizes.module.css";
@value textWhite, textGrey, textBlack, badgeOrange, planPanelBgGreen, planPanelBgBlue, panelBgGreen from "../Colors.module.css";
@value headingMargin2 from "../components/Heading.module.css";
@value flexRow, itemsGrow, rowSpaceEvenly, buttonsCenter from "../Layouts.module.css";

.connect {
}

.connectThankYou {
  composes: connect;
  background-image: url("../../assets/celebrate.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
}

.twoColumns {
  composes: rowSpaceEvenly;
  margin-top: 1rem;
}

@media (min-width: 1100px) {
  .twoColumns {
    width: 1100px;
  }
}

.fillHeight {
  align-items: stretch;
}

.billingForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formTitle {
  margin: 0 5px 1rem 5px;
}

.serviceAgreementTitle {
  text-align: center;
  margin-bottom: 1rem;
}

.promoCode {
  composes: flexRow itemsGrow;
  align-items: center;
  margin: .5rem 0;
}

.promoCode > *:last-child {
  margin: 0;
}

.blockHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blockHeading > *:first-child {
  margin-top: 0;
}

.block {
  border-radius: borderRadius;
  padding: 1rem;
}

.planInfo {
  composes: block;
  background-color: planPanelBgGreen;
}

.planData {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.planData > * {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.planData > *:not(:first-child) {
  margin-top: 1rem;
}

.routerInfo {
  composes: block;
  padding: 2rem 1rem;
  background-color: planPanelBgBlue;
  flex-grow: 1;
}

.routerText {
  max-width: 300px;
}

.checkboxes {
  max-width: 400px;
}

@media (min-width: 900px) {
  .checkboxes {
    max-width: 800px;
  }
}

.formRow {
  composes: flexRow itemsGrow;
}

.buttons {
  composes: buttonsCenter;
}

.thankYouText {
  max-width: 300px;
  background-color: panelBgGreen;
  padding: 1rem 2rem;
  margin: 2rem 0;
  border-radius: borderRadius;
}

.smallLogo {
  width: 40px;
  margin-top: 2rem;
}

.bottomNote {
  margin: 1rem;
}
