@value errorText, errorBg, successText, successBg, infoText, infoBg from "../Colors.module.css";
@value borderRadius from "../Sizes.module.css";

.message {
  padding: 0.5rem 1rem;
  border-radius: borderRadius;
  margin: 1rem 0;
}

.error {
  composes: message;
  color: errorText;
  background: errorBg;
}

.success {
  composes: message;
  color: successText;
  background: successBg;
}

.info {
  composes: message;
  color: infoText;
  background: infoBg;
}
