@value fontSofiaProBold from "../../Typography.module.css";
@value btnGrey, btnGreyHl, btnGreen, btnGreenHl, textWhite, textBlack from "../../Colors.module.css";
@value borderRadius from "../../Sizes.module.css";

.btn {
  font-family: fontSofiaProBold, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding: 12px 40px 10px 40px;
  border-radius: borderRadius;
}

.btn:disabled {
  opacity: 0.8;
}

.btn:hover:not(:disabled) {
  cursor: pointer;
}

.btnNormal {
  composes: btn;
  background: btnGrey;
  border: 1px solid btnGrey;
  color: textBlack;
}

.btnNormal:hover:not(:disabled) {
  background: btnGreyHl;
}

.btnPrimary {
  composes: btn;
  background: btnGreen;
  border: 1px solid btnGreen;
  color: textWhite;
}

.btnPrimary:hover:not(:disabled) {
  background: btnGreenHl;
}
