@value mainHeader from "../Typography.module.css";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageAlignTop {
  composes: page;
}

.pageAlignCenter {
  composes: page;
  min-height: 100vh;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.top {}

.title {
  composes: mainHeader;
}

.content {
  margin-bottom: 2rem;
}
