@value textWhite, stepperPrevious, stepperCurrent, stepperNext from "../Colors.module.css";

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin: 0 .25rem;
}

.step > img {
  width: 21px;
  height: 21px;
  position: relative;
  left: 2px;
  top: -2px;
}

.step > div {
  position: relative;
  top: 1px;
}

.stepPrevious {
  composes: step;
  background: stepperPrevious;
}

.stepCurrent {
  composes: step;
  background: stepperCurrent;
}

.stepNext {
  composes: step;
  background: stepperNext;
}

.text {
  color: textWhite;
}
