@value fontSofiaProBold, fontLarsseitRegular from "../Typography.module.css";
@value separatorGreen as sGreen, separatorOrange as sOrange from "../Colors.module.css";

.heading {
  line-height: 1.4;
  margin: 0;
}

.headingBold {
  composes: heading;
  font-family: fontSofiaProBold, sans-serif;
  font-weight: bold;
}

.headingRegular {
  composes: heading;
  font-family: fontLarsseitRegular, sans-serif;
  font-weight: normal;
}

/* Size */

.sizeBig {
  font-size: 2.5rem;
}

.sizeMedium {
  font-size: 2rem;
}

.sizeSmall {
  font-size: 1.5rem;
}

.sizeSmaller {
  font-size: 1.3rem;
}

/* Uppercase */

.uppercase {
  text-transform: uppercase;
}

/* Separator */

.separator {
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100px;
}

.separatorGreen {
  composes: separator;
  border-top: 8px solid sGreen;
}

.separatorOrange {
  composes: separator;
  border-top: 8px solid sOrange;
}

/* Top margin */

@value headingMargin0: 0;
@value headingMargin1: 1rem;
@value headingMargin2: 2rem;

.mt0 {
  margin: headingMargin0;
}

.mt1 {
  margin-top: headingMargin1;
}

.mt2 {
  margin-top: headingMargin2;
}
