@value fontLarsseitRegular, fontSofiaProBold from "../Typography.module.css";
@value pageBg, separatorGreen from "../Colors.module.css";
@value borderRadius from "../Sizes.module.css";

.thankYou {}

.content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.returnLink {
  text-transform: uppercase;
  font-family: fontSofiaProBold, 'sans-serif';
  font-weight: bold;
  font-size: 0.9rem;
  background-color: pageBg;
  padding: 4px 1.2rem 2px 4px;
  border-radius: borderRadius;
  background-image: url("../../assets/triangle-right.svg");
  background-position: right .15rem center;
  background-repeat: no-repeat;
  background-size: 0.8rem;
}
