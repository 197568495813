@value fieldBgNormal, errorBorder, errorText, placeholderColor, textLightGrey from "../../Colors.module.css";
@value borderRadius from "../../Sizes.module.css";
@value smallFontSize from "../../Typography.module.css";

@value labelHeight: 1.5rem;
@value inputFontSize: 1rem;
@value inputVerticalPadding: 10px;

.field {
  margin: 0 5px;
  display: flex;
  flex-direction: column;
}

.label {
  display: block;
  font-size: smallFontSize;
  height: labelHeight;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: fieldBgNormal;
  border: 1px solid fieldBgNormal;
  font-size: 1rem;
  line-height: 1rem;
  height: 1.1rem;
  padding: inputVerticalPadding 30px;
  border-radius: borderRadius;
}

.inputNormal {
  composes: input;
}

.inputError {
  composes: input;
  border-color: errorBorder;
}

.bold {
  font-weight: bold;
}

.grey {
  color: textLightGrey;
}

.input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: placeholderColor;
}
.input::-moz-placeholder { /* Firefox 19+ */
  color: placeholderColor;
}
.input:-ms-input-placeholder { /* IE 10+ */
  color: placeholderColor;
}
.input:-moz-placeholder { /* Firefox 18- */
  color: placeholderColor;
}
.input::placeholder {
  color: placeholderColor;
}
.inputWithPlaceholder { /* For fake fields, e.g. Dropdown */
  color: placeholderColor;
}

.error {
  font-size: smallFontSize;
  color: errorText;
}
