@value fontLarsseitRegular from "../Typography.module.css";

.alreadyConnected {}

.content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts {
  margin-top: 1rem;
}

@value iconSize: 2.5rem;

.contact {
  line-height: iconSize;
  padding-left: calc(iconSize + 0.5rem);
  background-size: iconSize;
  background-position: left center;
  background-repeat: no-repeat;
}

.call {
  composes: contact;
  background-image: url("../../assets/call.png");
}

.email {
  composes: contact;
  background-image: url("../../assets/email.png");
}
