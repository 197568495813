@value fontSofiaProBold, fontLarsseitRegular, bigFontSize, smallFontSize from "../Typography.module.css";
@value linkNormalBlue, linkHoverBlue, linkNormalBlack, linkHoverBlack from "../Colors.module.css";

.text {}

/* Paragraphs */

.text p {
  margin: .5rem 0;
}

/* Basic links */

.text a,
.text a:link,
.text a:visited {
  color: linkNormalBlue;
}

.text a:hover,
.text a:active {
  color: linkHoverBlue;
}

/* Black links */

.linksBlack {}

.text.linksBlack a,
.text.linksBlack a:link,
.text.linksBlack a:visited {
  color: linkNormalBlack;
}

.text.linksBlack a:hover,
.text.linksBlack a:active {
  color: linkHoverBlack;
}

/* Links with underline 'always' */

.linksUnderlineAlways {}

.text.linksUnderlineAlways a,
.text.linksUnderlineAlways a:link,
.text.linksUnderlineAlways a:visited,
.text.linksUnderlineAlways a:hover,
.text.linksUnderlineAlways a:active {
  text-decoration: underline;
}

/* Links with underline 'hover' */

.linksUnderlineHover {}

.text.linksUnderlineHover a,
.text.linksUnderlineHover a:link,
.text.linksUnderlineHover a:visited {
  text-decoration: none;
}

.text.linksUnderlineHover a:hover,
.text.linksUnderlineHover a:active {
  text-decoration: underline;
}

/* Links with underline 'notHover' */

.linksUnderlineNotHover {}

.text.linksUnderlineNotHover a,
.text.linksUnderlineNotHover a:link,
.text.linksUnderlineNotHover a:visited {
  text-decoration: underline;
}

.text.linksUnderlineNotHover a:hover,
.text.linksUnderlineNotHover a:active {
  text-decoration: none;
}

/* Links with underline 'none' */

.linksUnderlineNone {}

.text.linksUnderlineNone a,
.text.linksUnderlineNone a:link,
.text.linksUnderlineNone a:visited,
.text.linksUnderlineNone a:hover,
.text.linksUnderlineNone a:active {
  text-decoration: none;
}

/* Custom fonts - always fallback with Lato */

.sofia {
  font-family: fontSofiaProBold, Lato, sans-serif;
}

.larsseit {
  font-family: fontLarsseitRegular, Lato, sans-serif;
}

/* Sizes */

.normal {
  font-size: 1rem;
}

.big {
  font-size: bigFontSize;
}

.small {
  font-size: smallFontSize;
}

/* Alignment */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}
