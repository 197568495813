@value addressRow3to1, buttonsCenter from "../Layouts.module.css";

.checkAvailability {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/waves.png");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 500px) {
  .form {
    width: 500px;
  }
}

.addressRow {
  width: 100%;
}

.buttons {
  composes: buttonsCenter;
}
